/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useLayoutEffect, useRef, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { Menu, Dialog, Transition } from '@headlessui/react'
import { get, ref, onValue, startAt, orderByKey, limitToFirst, query } from "firebase/database";
import axios from "axios";
import ReactJson from 'react18-json-view';
import 'react18-json-view/src/style.css'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDownIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFileCsv, faCode, faRefresh, faEdit as IconEdit } from '@fortawesome/free-solid-svg-icons'
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { CalendarIcon, DocumentTextIcon, GlobeAltIcon, AdjustmentsHorizontalIcon, CodeBracketIcon, CodeBracketIcon as CustomCodeIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/20/solid'

import { AuthContext } from "../../utils/auth.js";
import CreateExtraction from '../../components/CreateExtraction.js';
import ViewFile from '../../components/ViewFile.js';
import ModalForNewExtraction from '../../components/utils/ModalForNewExtraction.js';
import ModalForViewFile from '../../components/utils/ModalForViewFile.js';
import { handleGenerateJson, handleGenerateExcel, handleGenerateCsv } from '../../components/functions/exportData.js';
import { db } from '../../utils/firebaseApp.js';
import Breadcrumb from '../../components/Breadcrumb';

import BatchHeading from './BatchFolder/BatchHeading.js';
import SelectFileStatus from './BatchFolder/SelectFileStatus.js';
import Pagination from './BatchFolder/Pagination.js';
import JsonViewerWindow from './BatchFolder/JsonViewer.js';
import { GoodNotificationCustom, ErrorNotificationCustom, GoodNotification, ErrorNotification } from './BatchFolder/Notifications.js';
import { ShowDeleteModalBatch, ShowDeleteModalFile, ShowDeleteModalFiles, ShowRedoModalFiles } from './BatchFolder/Modals.js';
import { DropdownWithDividerAndIcons, DropdownWithIcons } from './BatchFolder/Dropdowns.js';

import toast from 'react-hot-toast';

function classNames(...classes) { return classes.filter(Boolean).join(' ') }

export default function BatchScreen() {
  const { currentUser } = useContext(AuthContext);
  let userId = currentUser.uid;

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const environment = process.env.REACT_APP_ENV;

  const [isLoading, setIsLoading] = useState(true);

  const { batchId, extractionId } = useParams();
  const [files, setFiles] = useState([]);
  const [fields, setFields] = useState([]);
  const [extraction, setExtraction] = useState();
  const [batch, setBatch] = useState();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const maxCharactersForField = 75;
  const maxCharactersForFieldObject = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [noOfFiles, setNoOfFiles] = useState(0);

  const [showFileBool, setShowFileBool] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    file_id: '',
    file_url: '',
    file_name: '',
    status: '',
    data: {},
  });

  const [tabs, setTabs] = useState([
    { name: 'All', count: 0 },
    { name: 'To Review', count: 0 },
    { name: 'Confirmed', count: 0 },
    { name: 'Error', count: 0 },
  ]);

  const [currentTab, setCurrentTab] = useState('All');

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [showDeleteFile, setShowDeleteFile] = useState(false);
  const [selectedFileIdDelete, setSelectedFileIdDelete] = useState(null);

  const [showDeleteFiles, setShowDeleteFiles] = useState(false);

  const [showRedoFiles, setShowRedoFiles] = useState(false);

  const [showErrorCustom, setShowErrorCustom] = useState(false);
  const [errorCustomTitle, setErrorCustomTitle] = useState('');
  const [errorCustomSubtitle, setErrorCustomSubtitle] = useState('');

  const [showSuccessCustom, setShowSuccessCustom] = useState(false);
  const [successCustomTitle, setSuccessCustomTitle] = useState('');
  const [successCustomSubtitle, setSuccessCustomSubtitle] = useState('');

  const [selectAllFromBatch, setSelectAllFromBatch] = useState(false);

  const checkbox = useRef()
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])

  useLayoutEffect(() => {
    const isIndeterminate = selectedFiles.length > 0 && selectedFiles.length < files.length
    setChecked(selectedFiles.length === files.length && files.length > 0)
    setIndeterminate(isIndeterminate)
    checkbox.current.indeterminate = isIndeterminate
  }, [selectedFiles, files])

  function toggleAll() {
    setSelectedFiles(checked || indeterminate ? [] : files)
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  function resetSelectedFiles() {
    setSelectedFiles([]);
    setChecked(false);
    setIndeterminate(false);
    checkbox.current.indeterminate = false;
  }

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    resetSelectedFiles();
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
    resetSelectedFiles();
  }

  const selectPage = (pageNumber) => {
    if (currentPage === pageNumber) {
      return;
    } else {
      setCurrentPage(pageNumber);
      resetSelectedFiles();
    }
  }

  const fetchExtraction = async () => {
    let userId = currentUser.uid;
    const databaseRef = ref(db, 'extractions/' + userId + '/' + extractionId);

    try {
      const snapshot = await get(databaseRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        setFields(data.fields ?? []);
        if (data.batches && data.batches[batchId] && data.batches[batchId].files) {
          setNoOfFiles(data.batches[batchId].files.length);
        }
        setExtraction(data);
        setBatch(data.batches[batchId]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [unsubscribeFilesListener, setUnsubscribeFilesListener] = useState(null);

  const loadFiles = () => {
    setIsLoading(true);
    setFiles([]);

    // Define the path to query all files for a specific user's extraction and batch
    let queryPath = 'files/' + userId + '/' + extractionId + '/' + batchId;
    let itemsRef = ref(db, queryPath);

    // Query all files under the specified path
    const unsubscribe = onValue(itemsRef, (snapshot) => {
      const data = snapshot.val();
      let tempFiles = [];

      let countAll = 0;
      let countToReview = 0;
      let countConfirmed = 0;
      let countError = 0;

      if (data) {
        // Loop over each file in the data
        Object.keys(data).forEach((key) => {
          let file = data[key];
          file.id = key;

          // Create a new file object
          let newFile = {
            id: file.id,
            name: file.name ?? 'undefined',
            url: file.url,
            status: file.status,
            errorMessage: file.errorMessage,
            result: file.result ?? {},
          }

          // Filter files based on the current tab and status
          if (currentTab === 'All' && (file.status === 'processed' || file.status === 'waiting' || file.status === 'confirmed' || file.status === 'error')) {
            tempFiles.push(newFile);
          } else if (currentTab === 'To Review' && (file.status === 'processed' || file.status === 'waiting')) {
            tempFiles.push(newFile);
          } else if (currentTab === 'Confirmed' && file.status === 'confirmed') {
            tempFiles.push(newFile);
          } else if (currentTab === 'Error' && file.status === 'error') {
            tempFiles.push(newFile);
          }

          // Increment the counters based on the file status
          if (file.status === 'processed' || file.status === 'waiting' || file.status === 'confirmed' || file.status === 'error') countAll++;
          if (file.status === 'processed' || file.status === 'waiting') countToReview++;
          if (file.status === 'confirmed') countConfirmed++;
          if (file.status === 'error') countError++;
        });

        let totalItems = tempFiles.length;
        let tempNoOfPages = Math.ceil(totalItems / itemsPerPage);
        setNoOfPages(tempNoOfPages);

        // Ensure current page is valid and adjust if necessary
        if (currentPage > tempNoOfPages) {
          setCurrentPage(tempNoOfPages > 0 ? tempNoOfPages : 1);
        }

        // sort tempFiles: first is waiting, then processed, then confirmed, then error
        // tempFiles.sort((a, b) => {
        //   if (a.status === 'waiting' && b.status !== 'waiting') return -1;
        //   if (a.status === 'waiting' && b.status === 'waiting') return 0;
        //   if (a.status === 'processed' && b.status === 'confirmed') return -1;
        //   if (a.status === 'processed' && b.status === 'error') return -1;
        //   if (a.status === 'processed' && b.status === 'processed') return 0;
        //   if (a.status === 'confirmed' && b.status === 'error') return -1;
        //   if (a.status === 'confirmed' && b.status === 'confirmed') return 0;
        //   if (a.status === 'error' && b.status === 'error') return 0;
        //   return 1;
        // });

        let start = (currentPage - 1) * itemsPerPage;
        let end = start + itemsPerPage;
        // Slice the array to get the current page of items
        tempFiles = tempFiles.slice(start, end);

        // Update the state with the fetched files
        setFiles(tempFiles);

        // Update the tabs state with the new counts
        setTabs([
          { name: 'All', count: countAll },
          { name: 'To Review', count: countToReview },
          { name: 'Confirmed', count: countConfirmed },
          { name: 'Error', count: countError },
        ]);

        setIsLoading(false);
      }
    });

    // Set a function to unsubscribe from the database updates when needed
    setUnsubscribeFilesListener(() => unsubscribe);
  }

  const loadAllFiles = async () => {
    let userId = currentUser.uid;
    const databaseRef = ref(db, 'files/' + userId + '/' + extractionId + '/' + batchId);

    try {
      const snapshot = await get(databaseRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        return data;
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  }

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchExtraction();
    }
    fetchData();
    resetSelectedFiles();
  }, [extractionId, batchId]);

  useEffect(() => {
    if (extraction) {
      if (unsubscribeFilesListener) {
        unsubscribeFilesListener();
      }

      loadFiles();
    }

    return () => {
      if (unsubscribeFilesListener) {
        unsubscribeFilesListener();
      }
    };
  }, [currentPage, extraction, currentTab, itemsPerPage, refresh]);

  const showErrorFunction = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }

  const showSuccessFunction = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);
  }

  const showErrorCustomFunction = ({ title, subtitle }) => {
    setErrorCustomTitle(title);
    setErrorCustomSubtitle(subtitle);
    setShowErrorCustom(true);

    setTimeout(() => {
      setShowErrorCustom(false);
    }, 2000);
  }

  const showSuccessCustomFunction = ({ title, subtitle }) => {
    setSuccessCustomTitle(title);
    setSuccessCustomSubtitle(subtitle);
    setShowSuccessCustom(true);

    setTimeout(() => {
      setShowSuccessCustom(false);
    }, 2000);
  }

  const handleFileExport = async ({ index, type, isOneFile }) => {
    let currentDateTime = new Date().toISOString();
    let formattedDateTime = currentDateTime.slice(0, 10);
    let fileName = 'Extracta.ai - Data Export - ' + formattedDateTime;

    let outputArray = [];

    if (isOneFile) {
      const file = files[index];
      outputArray = [{
        file_name: file.name,
        data: file.result,
      }];
    } else {
      if (checked) {
        // get all the files from the batch
        const allFiles = await loadAllFiles();

        if (allFiles) {
          Object.keys(allFiles).forEach((key, index) => {
            let file = allFiles[key];
            outputArray.push({
              file_name: file.name,
              data: file.result,
            });
          });
        } else {
          showErrorFunction();
          return;
        }
      } else {
        // get only the selected files
        selectedFiles.forEach((file, index) => {
          outputArray.push({
            file_name: file.name,
            data: file.result,
          });
        });
      }
    }

    // if the file has more fields than the extraction's fields, remove the extra fields
    outputArray.forEach(item => {
      if (item.data !== null && item.data !== undefined) {
        Object.keys(item.data).forEach(key => {
          if (!extraction.fields.some(field => field.key === key)) {
            delete item.data[key];
          }
        });
      }
    });

    // if the file has less fields than the extraction's fields, add the missing fields with a value of '-'
    extraction.fields.forEach(field => {
      outputArray.forEach(item => {
        try {
          if (item.data === null || item.data === undefined) {
            if (!item.data.hasOwnProperty(field.key)) {
              item.data[field.key] = '-';
            }
          }
        } catch (error) { }
      });
    });

    switch (type) {
      case 'json':
        handleGenerateJson(outputArray, fileName, extraction.fields);
        break;
      case 'excel':
        handleGenerateExcel(outputArray, fileName, extraction.fields);
        break;
      case 'csv':
        handleGenerateCsv(outputArray, fileName, extraction.fields);
        break;
      default:
        break;
    }

    showSuccessFunction();
  }

  const selectAllFromBatchFunction = () => {
    if (selectAllFromBatch) {
      setSelectAllFromBatch(false);
    } else {
      setSelectAllFromBatch(true);
      resetSelectedFiles();
      toggleAll();
    }
  }

  const deleteBatch = async () => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/delete";

      await axios.post(url, {
        "extractionId": extractionId,
        "batchId": batchId,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then(async (response) => {
        if (response.status === 200) {
          showSuccessCustomFunction({ title: 'Success', subtitle: 'Batch deleted successfully.' });
          await new Promise(r => setTimeout(r, 2000));
          window.location.href = "/extractions/" + extractionId;
        } else {
          showErrorCustomFunction({ title: 'Error', subtitle: 'An error occurred while trying to delete the batch.' });
        }
      }).catch((error) => {
        showErrorCustomFunction({ title: 'Error', subtitle: 'An error occurred while trying to delete the batch.' });
      });
    });
  }

  const reloadFiles = async () => {
    let fileIds = [];

    if (checked) {
      const allFiles = await loadAllFiles();

      if (allFiles) {
        Object.keys(allFiles).forEach((key, index) => {
          fileIds.push(key);
        });
      } else {
        showErrorFunction();
        return;
      }
    } else {
      fileIds = selectedFiles.map(file => file.id);
    }

    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/redoFiles";

      await axios.post(url, {
        "extractionId": extractionId,
        "batchId": batchId,
        "fileIds": fileIds,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then(async (response) => {
        if (response.status === 200) {
          await fetchExtraction();
          showSuccessCustomFunction({ title: 'Success', subtitle: 'Files will be processed shortly.' });
        } else {
          let errorTitle = 'Error';
          let errorSubtitle = 'An error occurred while trying to redo the files.';
          showErrorCustomFunction({ title: errorTitle, subtitle: errorSubtitle });
        }
      }).catch((error) => {
        let errorTitle = error.response.data.title ?? 'Error';
        let errorSubtitle = error.response.data.subtitle ?? 'An error occurred while trying to redo the files.';
        showErrorCustomFunction({ title: errorTitle, subtitle: errorSubtitle });
      });
    });
  }

  const reloadFile = async ({ fileId }) => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/redoFile";

      await axios.post(url, {
        "extractionId": extractionId,
        "batchId": batchId,
        "fileId": fileId,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then(async (response) => {
        if (response.status === 200) {
          await fetchExtraction();
          showSuccessCustomFunction({ title: 'Success', subtitle: 'File will be processed shortly.' });
        } else {
          let errorTitle = 'Error';
          let errorSubtitle = 'An error occurred while trying to redo the file.';
          showErrorCustomFunction({ title: errorTitle, subtitle: errorSubtitle });
        }
      }).catch((error) => {
        let errorTitle = error.response.data.title ?? 'Error';
        let errorSubtitle = error.response.data.subtitle ?? 'An error occurred while trying to redo the file.';
        showErrorCustomFunction({ title: errorTitle, subtitle: errorSubtitle });
      });
    });
  }

  const deleteFile = async ({ fileId }) => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/delete";

      await axios.post(url, {
        "extractionId": extractionId,
        "batchId": batchId,
        "fileId": fileId,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then(async (response) => {
        if (response.status === 200) {
          await fetchExtraction();
        } else {
        }
      }).catch((error) => {
      });
    });
  }

  const deleteFiles = async () => {
    if (checked) {
      deleteBatch();
    } else {
      selectedFiles.forEach((file, index) => {
        deleteFile({ fileId: file.id });
      });

      setRefresh(!refresh);
    }
    resetSelectedFiles();
  }

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOneFile, setIsOneFile] = useState(false);

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(Number(dateString)).toLocaleDateString(undefined, options);
  }

  const openFileResult = async (file) => {
    setSelectedFile({
      file_id: file.id,
      file_url: file.url,
      file_name: file.name,
      status: file.status,
      data: file.result,
    })

    setShowFileBool(true);
  }

  const pages = [
    { name: extraction == null ? extractionId : extraction.name, href: '/extractions/' + extractionId, current: false },
    { name: 'Batch ' + batchId, href: '/extractions/' + extractionId + '/batch/' + batchId, current: true },
  ]

  const [isChangingTab, setIsChangingTab] = useState(false);

  const changeTab = (tab) => {
    if (tab === currentTab) return;

    setCurrentTab(tab);
    setCurrentPage(1);

    // unselect all files
    resetSelectedFiles();

    setIsChangingTab(true);
    setTimeout(() => {
      setIsChangingTab(false);
    }, 250);
  }

  return (
    <div className="">
      <Breadcrumb pages={pages} currentHref={"/extractions"} />

      <ErrorNotification showError={showError} setShowError={setShowError} />
      <GoodNotification showError={showSuccess} setShowError={setShowSuccess} />

      <ErrorNotificationCustom showError={showErrorCustom} setShowError={setShowErrorCustom} errorTitle={errorCustomTitle} errorSubtitle={errorCustomSubtitle} />
      <GoodNotificationCustom showError={showSuccessCustom} setShowError={setShowSuccessCustom} successfulTitle={successCustomTitle} successfulSubtitle={successCustomSubtitle} />

      <ShowDeleteModalBatch open={showDelete} setOpen={setShowDelete} deleteFunction={() => {
        deleteBatch();
        setShowDelete(false);
      }} />

      <ShowDeleteModalFile open={showDeleteFile} setOpen={setShowDeleteFile} deleteFunction={() => {
        deleteFile({ fileId: selectedFileIdDelete });
        setShowDeleteFile(false);
      }} />

      <ShowDeleteModalFiles open={showDeleteFiles} setOpen={setShowDeleteFiles} noOfFiles={checked ? noOfFiles : selectedFiles.length} isBatch={checked} deleteFunction={() => {
        deleteFiles();
        setShowDeleteFiles(false);
      }} />

      <ShowRedoModalFiles open={showRedoFiles} setOpen={setShowRedoFiles} noOfFiles={checked ? noOfFiles : selectedFiles.length} isBatch={checked} redoFunction={async () => {
        await reloadFiles();
        setShowRedoFiles(false);
        resetSelectedFiles();
      }} />

      <ModalForNewExtraction
        open={open}
        setOpen={setOpen}
        close={() => { }}
      >
        <CreateExtraction
          closeModal={() => {
            setOpen(false);
            changeTab('All');
          }}
        />
      </ModalForNewExtraction>

      <ModalForNewExtraction
        open={open2}
        setOpen={setOpen2}
        close={() => { }}
      >
        {/* edit extraction */}
        <CreateExtraction isEdit={true} closeModal={() => {
          setOpen2(false);
          fetchExtraction();
        }} />
      </ModalForNewExtraction>

      <ModalForViewFile open={showFileBool} setOpen={setShowFileBool} >
        <ViewFile
          closeModal={() => {
            setShowFileBool(false);
            setSelectedFile({
              file_id: '',
              file_url: '',
              file_name: '',
              status: '',
              data: {},
            });
          }}
          extractionId={extractionId}
          batchId={batchId}
          fileData={selectedFile}
          extraction={extraction}
          initialFileStatus={currentTab}
        />
      </ModalForViewFile>

      {showExportModal &&
        <ExportDialog
          open={showExportModal}
          setOpen={setShowExportModal}
          files={files}
          isOneFile={isOneFile}
          index={selectedIndex}
          checked={checked}
          selectedFiles={selectedFiles}
          loadAllFiles={loadAllFiles}
          extraction={extraction}
        />
      }

      <div className="">
        <BatchHeading
          batchName={"Batch"}
          batchCreatedAt={formatDate(extraction?.batches[batchId].startTime)}
          batchUpdatedAt={formatDate(extraction?.batches[batchId].startTime)}
          // batchFilesCount={extraction?.batches[batchId].files.length}
          batchFilesCount={extraction?.batches[batchId]?.files?.length || 0}
          batchFieldsCount={extraction?.fields.length}
          batchOrigin={extraction?.batches[batchId].origin}
          deleteBatch={() => setShowDelete(true)}
          editExtraction={() => setOpen2(true)}
          extractionId={extractionId}
          batchId={batchId}
        />
      </div>

      <div className="sm:flex sm:items-center mt-7">
        <div className="sm:flex-auto">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Files</h3>
          <p className="mt-1 text-sm text-gray-500">
            A list of all files uploaded to this batch.
          </p>
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={() => setOpen(true)}
            className="ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Add files
          </button>
        </div>
      </div>

      <div className="mt-5">
        <SelectFileStatus
          tabs={tabs}
          currentTab={currentTab}
          changeTab={changeTab}
        />
      </div>

      <div className="mt-2 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="relative">
              {selectedFiles.length > 0 && (
                <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                  {!checked && (
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      {selectedFiles.length} {selectedFiles.length > 1 ? 'files' : 'file'} selected
                    </button>
                  )}
                  {checked && (
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      {noOfFiles} {noOfFiles > 1 ? 'files' : 'file'} selected
                    </button>
                  )}
                  {selectAllFromBatch &&
                    <button
                      onClick={() => selectAllFromBatchFunction()}
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      All batch selected
                    </button>}
                  {!checked && (
                    <button
                      type="button"
                      onClick={() => setShowRedoFiles(true)}
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Redo {selectedFiles.length > 1 ? 'files' : 'file'}
                    </button>)}
                  {checked && (
                    <button
                      type="button"
                      onClick={() => setShowRedoFiles(true)}
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    >
                      Redo {noOfFiles > 1 ? 'files' : 'file'}
                    </button>)}
                  <button
                    type="button"
                    onClick={() => setShowDeleteFiles(true)}
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  >
                    Delete
                  </button>
                  <Menu as="div" className="relative inline-block text-left overflow-visible">
                    <div className='z-50' onClick={() => {
                      setIsOneFile(false);
                      setSelectedIndex(0);
                      setShowExportModal(true);
                    }}>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Export As
                        <ChevronRightIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
              )}

              <table className={classNames("min-w-full table-fixed divide-y divide-gray-300",
                files.length <= 0 ? 'hidden' : ''
              )}>
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className={classNames("relative px-7 sm:w-12 sm:px-6", (isChangingTab || files.length <= 0) ? 'hidden' : '')}
                    >
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                      {isChangingTab && <Skeleton width={80} height={20} className="" />}
                      {!isChangingTab && "File name".toUpperCase()}
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {isChangingTab && <Skeleton width={80} height={20} className="" />}
                      {!isChangingTab && "Status".toUpperCase()}
                    </th>
                    {fields.map((field) => (
                      <th key={field.key} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {isChangingTab && <Skeleton width={80} height={20} className="" />}
                        {!isChangingTab && field.key.toUpperCase()}
                      </th>
                    ))}
                    {selectedFiles.length <= 0 &&
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    }
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isChangingTab && (
                    <tr>
                      <td colSpan={fields.length + 3} className="py-8">
                        <Skeleton className='mb-3' height={20} count={itemsPerPage} />
                      </td>
                    </tr>
                  )}

                  {!isChangingTab && files.map((file, index) => (
                    <tr key={file.id} className={selectedFiles.includes(file) ? 'bg-gray-50' : undefined}>
                      <td className="relative px-7 sm:w-12 sm:px-6">
                        {selectedFiles.includes(file) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          value={file.id}
                          checked={selectedFiles.includes(file)}
                          onChange={(e) =>
                            setSelectedFiles(
                              e.target.checked
                                ? [...selectedFiles, file]
                                : selectedFiles.filter((p) => p !== file)
                            )
                          }
                        />
                      </td>
                      <td
                        className={classNames(
                          'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                          selectedFiles.includes(file) ? 'text-indigo-600' : 'text-gray-900'
                        )}
                      >
                        <div className='flex items-center group'>
                          <button onClick={() => openFileResult(file)}>
                            <span className='group-hover:underline group-hover:text-indigo-600 mr-1.5 cursor-pointer'>
                              {file.name}
                            </span>
                          </button>
                          <button
                            onClick={() => openFileResult(file)}
                            className="inline-flex items-center justify-center h-6 w-6 rounded-full text-gray-400 group-hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500"
                          >
                            <FontAwesomeIcon icon={IconEdit} />
                          </button>
                        </div>
                      </td>
                      <td key={index} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500" >
                        <span className='text-red-300'>
                          {file.status === 'error' ? 'error' : ''}
                          {file.status === 'error' ? (
                            file.errorMessage !== undefined ? (': ' + file.errorMessage) : ''
                          ) : ''}
                        </span>
                        <span className='text-orange-300'>
                          {file.status === 'waiting' ? 'processing' : ''}
                        </span>
                        <span className=''>
                          {file.status === 'processed' ? 'processed' : ''}
                        </span>
                        <span className=''>
                          {file.status === 'confirmed' ? 'confirmed' : ''}
                        </span>
                      </td>
                      {fields.map((field, index) => {
                        if (isChangingTab) {
                          return (
                            <td key={index} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Skeleton width={80} height={20} className="" />
                            </td>
                          );
                        }

                        const value = file.result[field.key];
                        let displayValue;
                        let titleValue = ''; // Used for hover tooltip
                        let type = '';

                        if (typeof value === 'string') {
                          displayValue = value.length > maxCharactersForField ? value.slice(0, maxCharactersForField) + '...' : value;
                          titleValue = value.length > maxCharactersForField ? value : '';
                          type = 'string';
                        } else if (value) {
                          // Convert object or list to string
                          const stringValue = JSON.stringify(value);
                          displayValue = stringValue.length > maxCharactersForFieldObject ? stringValue.slice(0, maxCharactersForFieldObject) + '...' : stringValue;
                          titleValue = stringValue.length > maxCharactersForFieldObject ? stringValue : '';
                          type = 'object';
                        } else {
                          displayValue = '-';
                        }

                        if (type === 'object') {
                          return (
                            <td key={index} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className='flex flex-row items-center'>
                                <div className="truncate" title={titleValue}>
                                  {displayValue}
                                </div>
                                <JsonViewerWindow key={index} jsonData={value} />
                              </div>
                            </td>
                          );
                        } else {
                          return (
                            <td key={index} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="truncate" title={titleValue}>
                                {displayValue}
                              </div>
                            </td>
                          );
                        }
                      })}
                      {selectedFiles.length <= 0 &&
                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3 relative">
                          <DropdownWithDividerAndIcons
                            className="overflow-visible"
                            file={file}
                            exportAs={() => {
                              setIsOneFile(true);
                              setSelectedIndex(index);
                              setShowExportModal(true);
                            }}
                            reloadFile={reloadFile}
                            deleteFile={({ fileId }) => {
                              setShowDeleteFile(true);
                              setSelectedFileIdDelete(fileId);
                            }}
                            initialButton={
                              <Menu.Button className="flex items-center">
                                <span className="text-indigo-600 hover:text-indigo-900">
                                  Options<span className="sr-only">, {file.name}</span>
                                </span>
                              </Menu.Button>
                            }
                          />
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Show a message if there are no files */}
              {!isChangingTab && files.length <= 0 && currentTab === 'All' &&
                <div className="mt-4 flex-1 flex flex-col items-center justify-center h-full rounded-lg bg-gray-100 p-12">
                  <div className="text-center">
                    <h2 className="text-xl font-semibold text-gray-900">No files yet</h2>
                    <p className="mt-1.5 text-sm text-gray-600">
                      Upload files to this batch.
                    </p>
                    <button
                      onClick={() => setOpen(true)}
                      className="mt-3 ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      Add files
                    </button>
                  </div>
                </div>
              }
              {files.length <= 0 && !isChangingTab && currentTab !== 'All' && (
                <div className="mt-4 flex-1 flex flex-col items-center justify-center h-full rounded-lg bg-gray-100 p-16">
                  <div className="text-center">
                    <h2 className="text-xl font-semibold text-gray-900">There are no files with this status</h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {
        files.length > 0 && (
          <Pagination
            numberOfPages={noOfPages}
            currentPageNumber={currentPage}
            previousButton={previousPage}
            nextButton={nextPage}
            selectPage={selectPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />)
      }
    </div >
  )
}

function ExportDialog({ open, setOpen, files, isOneFile, index, checked, selectedFiles, loadAllFiles, extraction }) {
  const cancelButtonRef = useRef(null);

  const arrayFields = extraction.fields.filter(field => field.type === 'array');

  const [fileFormat, setFileFormat] = useState('excel'); // Initially no file format selected
  const [exportType, setExportType] = useState('normal'); // Default export type is 'normal'
  const [selectedField, setSelectedField] = useState('');

  const handleFileExport = async () => {
    try {
      let currentDateTime = new Date().toISOString();
      let formattedDateTime = currentDateTime.slice(0, 10);
      let fileName = 'Extracta.ai - Data Export - ' + formattedDateTime;

      let outputArray = [];

      if (isOneFile) {
        const file = files[index];
        outputArray = [{
          file_name: file.name,
          data: file.result,
        }];
      } else {
        if (checked) {
          // get all the files from the batch
          const allFiles = await loadAllFiles();

          if (allFiles) {
            Object.keys(allFiles).forEach((key, index) => {
              let file = allFiles[key];
              outputArray.push({
                file_name: file.name,
                data: file.result,
              });
            });
          } else {
            toast.error('Error exporting file!');
            return;
          }
        } else {
          // get only the selected files
          selectedFiles.forEach((file, index) => {
            outputArray.push({
              file_name: file.name,
              data: file.result,
            });
          });
        }
      }

      // if the file has more fields than the extraction's fields, remove the extra fields
      outputArray.forEach(item => {
        if (item.data !== null && item.data !== undefined) {
          Object.keys(item.data).forEach(key => {
            if (!extraction.fields.some(field => field.key === key)) {
              delete item.data[key];
            }
          });
        }
      });

      // if the file has less fields than the extraction's fields, add the missing fields with a value of '-'
      extraction.fields.forEach(field => {
        outputArray.forEach(item => {
          try {
            if (item.data === null || item.data === undefined) {
              if (!item.data.hasOwnProperty(field.key)) {
                item.data[field.key] = '-';
              }
            }
          } catch (error) { }
        });
      });

      const hasArrayField = extraction.fields.some(field => field.type === 'array');
      let extractionFields = extraction.fields;

      if ((fileFormat === 'excel' || fileFormat === 'csv') && hasArrayField) {
        if (exportType === 'multipleRows') {
          const chosenArrayField = selectedField;
          const { expandedOutput, newFields } = expandArrayField(outputArray, chosenArrayField, extraction.fields);
          extractionFields = newFields;
          outputArray = expandedOutput;
        }
      }

      switch (fileFormat) {
        case 'json':
          handleGenerateJson(outputArray, fileName, extractionFields);
          break;
        case 'excel':
          handleGenerateExcel(outputArray, fileName, extractionFields);
          break;
        case 'csv':
          handleGenerateCsv(outputArray, fileName, extractionFields);
          break;
        default:
          break;
      }
      toast.success('File exported successfully!');
      setOpen(false);
    } catch (error) {
      toast.error('Error exporting file!');
      setOpen(false);
    }
  }

  const expandArrayField = (outputArray, arrayFieldKey, fields) => {
    let expandedOutput = [];

    // Find the index of the original array field in the fields array
    const originalFieldIndex = fields.findIndex(field => field.key === arrayFieldKey);

    // Filter out the original array field from the new fields array (but only for object arrays)
    let newFields = fields.filter(field => field.key !== arrayFieldKey);

    // Find the arrayField structure in extraction.fields to get the properties
    const arrayFieldStructure = fields.find(field => field.key === arrayFieldKey);
    let properties = [];

    if (arrayFieldStructure && arrayFieldStructure.items && arrayFieldStructure.items.properties) {
      // Sort the properties based on the 'id' field to maintain the correct order
      properties = arrayFieldStructure.items.properties.sort((a, b) => a.id - b.id);
    }

    // Loop through each item in the outputArray
    outputArray.forEach(item => {
      let data = item.data;

      // Find the field that corresponds to the chosen array field
      let arrayField = data[arrayFieldKey];

      // If the field is not an array or does not exist, skip
      if (!Array.isArray(arrayField)) {
        expandedOutput.push(item); // Just push the original data if it's not an array
        return;
      }

      // Check if the array contains objects or strings
      if (typeof arrayField[0] === 'object' && !Array.isArray(arrayField[0])) {
        // Case where array items are objects (expand each object's properties into separate columns)
        arrayField.forEach(arrayEntry => {
          let newRow = { ...data }; // Clone the original data

          // Remove the arrayFieldKey from the newRow (we're expanding its fields)
          delete newRow[arrayFieldKey];

          // Spread object keys (from arrayEntry) into multiple columns in the new row using child keys only, keeping the order based on `properties`
          properties.forEach(property => {
            const key = property.key;
            newRow[key] = arrayEntry[key];
          });

          // Add the expanded row to the output
          expandedOutput.push({
            ...item, // Keep the rest of the item info (e.g., file name)
            data: newRow // Update data with the expanded row
          });
        });

        // Insert the new fields at the index of the original array field
        const newFieldEntries = properties.map(property => ({
          description: property.description,
          key: property.key,
          type: property.type,
        }));

        // Insert the new field entries at the original index
        newFields.splice(originalFieldIndex, 0, ...newFieldEntries);
      } else if (typeof arrayField[0] === 'string') {
        // Case where array items are strings (concatenate the strings with commas)
        let newRow = { ...data };

        // Join the array of strings into a single string, separated by commas
        newRow[arrayFieldKey] = arrayField.join(', ');

        // Add the updated row to the expanded output
        expandedOutput.push({
          ...item, // Keep the rest of the item info
          data: newRow // Update data with the concatenated string
        });

        // Ensure the original array field is retained in newFields (only if it's a string array)
        const originalField = fields.find(field => field.key === arrayFieldKey);
        if (originalField) {
          newFields.splice(originalFieldIndex, 0, originalField);
        }
      }
    });

    return { expandedOutput, newFields }; // Return both the expanded output and the updated fields
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
                  <h2 className="text-xl font-semibold mb-6">Export Options</h2>

                  <div className="space-y-4 mb-2">
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">File Format</label>
                      <div className="flex space-x-2">
                        <button
                          className={`flex-1 px-4 py-2 border rounded-md ${fileFormat === 'excel' ? 'bg-indigo-600 text-white border-indigo-600' : 'border-gray-300'
                            }`}
                          onClick={() => setFileFormat('excel')}
                        >
                          Excel
                        </button>
                        <button
                          className={`flex-1 px-4 py-2 border rounded-md ${fileFormat === 'csv' ? 'bg-indigo-600 text-white' : 'border-gray-300'
                            }`}
                          onClick={() => setFileFormat('csv')}
                        >
                          CSV
                        </button>
                        <button
                          className={`flex-1 px-4 py-2 border rounded-md ${fileFormat === 'json' ? 'bg-indigo-600 text-white' : 'border-gray-300'
                            }`}
                          onClick={() => setFileFormat('json')}
                        >
                          JSON
                        </button>
                      </div>
                    </div>

                    {fileFormat && fileFormat !== 'json' && (
                      <>
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">Export Type</label>
                          <div className="flex flex-col space-y-1">
                            <div className="flex items-center space-x-2">
                              <input
                                type="radio"
                                value="normal"
                                checked={exportType === 'normal'}
                                onChange={() => setExportType('normal')}
                                id="normal"
                                className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                              />
                              <label htmlFor="normal" className="text-sm text-gray-700">
                                Normal Export
                              </label>
                            </div>
                            {arrayFields.length > 0 && (
                              <div className="flex items-center space-x-2">
                                <input
                                  type="radio"
                                  value="multipleRows"
                                  checked={exportType === 'multipleRows'}
                                  onChange={() => setExportType('multipleRows')}
                                  id="multipleRows"
                                  className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                />
                                <label htmlFor="multipleRows" className="text-sm text-gray-700">
                                  Multiple Rows Export
                                </label>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="space-y-2">
                          <p className="text-sm text-gray-500">
                            {exportType === 'normal'
                              ? 'Normal Export: All fields are transformed into columns in the exported file.'
                              : 'Multiple Rows Export: Select a field of type array to be expanded across multiple rows.'}
                          </p>
                          {exportType === 'multipleRows' && (
                            <select
                              value={selectedField}
                              onChange={(e) => setSelectedField(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                              <option value="" disabled>
                                Select a field
                              </option>
                              {arrayFields.map((field) => (
                                <option key={field.key} value={field.key}>
                                  {field.key}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:w-auto ${!fileFormat || (fileFormat !== 'json' && exportType === 'multipleRows' && !selectedField)
                      ? 'bg-gray-300 cursor-not-allowed text-gray-500'
                      : 'bg-indigo-600 text-white hover:bg-indigo-500'
                      }`}
                    onClick={handleFileExport}
                    disabled={!fileFormat || (fileFormat !== 'json' && exportType === 'multipleRows' && !selectedField)}
                  >
                    Export File
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    ref={cancelButtonRef}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}