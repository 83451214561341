import { React, useContext, useEffect, useState } from 'react'
import axios from 'axios';

import { AuthContext } from "../../utils/auth.js";
import { ref, onValue } from "firebase/database";
import { db } from '../../utils/firebaseApp.js';

import { PlusIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

import ModalForNewExtraction from '../../components/utils/ModalForNewExtraction.js';
import CreateExtraction from '../../components/CreateExtraction.js';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    'in progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    processing: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

export default function ExtractionsList() {
    const { currentUser } = useContext(AuthContext);
    const [extractions, setExtractions] = useState([]);

    useEffect(() => {
        let userId = currentUser.uid;

        onValue(ref(db, 'extractions/' + userId), (snapshot) => {
            const data = snapshot.val();
            if (data != null) {
                setExtractions([]);

                let tempExtractions = [];
                Object.keys(data).forEach(function (key) {
                    let extraction = data[key];
                    extraction.id = key;

                    let batches = extraction.batches ?? [];

                    let extractionStatus = 'complete';
                    Object.keys(batches).forEach(function (key) {
                        let batch = batches[key];
                        if (batch.status === 'processing') {
                            extractionStatus = 'processing';
                        }
                    });

                    let newExtraction = {
                        name: extraction.name ?? 'undefined',
                        description: extraction.description ?? '',
                        status: extractionStatus,
                        language: extraction.language,
                        noOfBatches: 0,
                        noOfFields: 0,
                        batches: extraction.batches,
                        fields: extraction.fields,
                        id: extraction.id,
                        createdAt: extraction.createdAt ?? Date.now(),
                        workflowId: extraction.workflowId ?? '',
                    }

                    if (extraction.batches && typeof extraction.batches === 'object') {
                        newExtraction.noOfBatches = Object.keys(extraction.batches).length;
                    } else {
                        newExtraction.noOfBatches = 0;
                    }

                    if (extraction.fields && typeof extraction.fields === 'object') {
                        newExtraction.noOfFields = Object.keys(extraction.fields).length;
                    } else {
                        newExtraction.noOfFields = 0;
                    }

                    tempExtractions.push(newExtraction);
                });

                const sortedTempExtractions = tempExtractions.sort((a, b) => Number(b.createdAt) - Number(a.createdAt));
                setExtractions(sortedTempExtractions);
            }
        });
    }, []);

    const [open, setOpen] = useState(false);

    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const environment = process.env.REACT_APP_ENV;

    const deleteExtraction = (extractionId) => {
        currentUser.getIdToken().then((idToken) => {
            const url = serverUrl + "/delete";

            axios.post(url, {
                "extractionId": extractionId,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then((response) => {
                if (response.status === 200) {
                    console.log("hey merge");
                    // window.location.href = "/extractions";
                } else {
                    console.log("hey nu merge");
                }
            }).catch((error) => {
                console.log("hey nu merge");
            });
        });
    }

    return (
        <div>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Extractions</h1>
                    <p className="mt-1 text-sm text-gray-700">
                        A list of all the extractions that have been uploaded.
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        onClick={() => setOpen(true)}
                        className="ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        New extraction
                    </button>
                </div>
            </div>

            <ModalForNewExtraction open={open} setOpen={setOpen} close={() => { }}>
                <CreateExtraction
                    closeModal={(extractionId) => {
                        setOpen(false);
                        if (extractionId) {
                            window.location.href = "/extractions/" + extractionId;
                        } else {
                            window.location.reload();
                        }
                    }}
                    extractionsLength={extractions.length}
                />
            </ModalForNewExtraction>

            <ul className="divide-y mt-6 divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                {extractions.map((extraction) => (
                    <li key={extraction.id} className="cursor-pointer gap-x-6 hover:bg-gray-50">
                        <a href={"/extractions/" + extraction.id} className='px-4 py-5 sm:px-6 relative flex justify-between'>
                            <div className="flex min-w-0 gap-x-4">
                                <div className="min-w-0 flex-auto">
                                    <div className="flex items-start gap-x-3">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">{extraction.name}</p>
                                        <p
                                            className={classNames(
                                                statuses[extraction.status],
                                                'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                            )}
                                        >
                                            {extraction.status}
                                        </p>
                                    </div>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                        {(extraction.description !== null && extraction.description.length > 0) &&
                                            <>
                                                <p className="whitespace-nowrap">
                                                    {extraction.description}
                                                </p>
                                                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                                    <circle cx={1} cy={1} r={1} />
                                                </svg>
                                            </>
                                        }
                                        <p className="truncate">{extraction.noOfBatches} Batches</p>
                                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <p className="truncate">{extraction.noOfFields} Fields</p>
                                        {environment === 'development' &&
                                            <>
                                                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                                    <circle cx={1} cy={1} r={1} />
                                                </svg>
                                                <p>workflowId: {extraction.workflowId}</p>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex shrink-0 items-center gap-x-4">
                                <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                            </div>
                            {/* {environment === 'development' &&
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteExtraction(extraction.id);
                                    }}
                                    className="absolute top-0 right-0 p-2 text-gray-400 hover:text-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                >
                                    Delete
                                </button>
                            } */}
                        </a>
                    </li>
                ))}
            </ul>

            {extractions.length === 0 &&
                <div className="mt-4 flex-1 flex flex-col items-center justify-center h-full rounded-lg bg-gray-100 p-8">
                    <div className="text-center">
                        <h2 className="text-xl font-semibold text-gray-900">No extractions yet</h2>
                        <p className="mt-1.5 text-sm text-gray-600">
                            Get started by creating a new extraction.
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}